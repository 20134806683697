export const countryDataInfo = {
    "nigeria@talentasker.com": {
        "CountryName": "Nigeria",
        "countryCode": "NG",
        "currency": "NGN",
        "Nationality": "Nigerian",
        "CurrencySymbol": "₦",
        "baseUrl": "https://naizeria-live-api.talentasker.com",
        "imageURL": "../../../assets/Nigeria.png",
    },
    "phuket@talentasker.com": {
        "CountryName": "Thailand",
        "countryCode": "TH",
        "currency": "THB",
        "Nationality": "Thai",
        "CurrencySymbol": "฿",
        "baseUrl": "https://phuket-live-api.talentasker.com",
        "imageURL": "../../../assets/Thailand.png",
    },
    "malyasia@talentasker.com": {
        "CountryName": "Malaysia",
        "countryCode": "MY",
        "currency": "MYR",
        "Nationality": "Malaysian",
        "CurrencySymbol": "RM",
        "baseUrl": "https://malyasia-live-api.talentasker.com",
        "imageURL": "../../../assets/Malaysia.png",
    },
    "india@talentasker.com": {
        "CountryName": "India",
        "countryCode": "IN",
        "currency": "INR",
        "Nationality": "Indian",
        "CurrencySymbol": "₹",
        "baseUrl": "https://india-live-api.talentasker.com",
        "imageURL": "../../../assets/India.png",
    },
    "info@talentasker.com" :{
        "CountryName": "Taiwan",
        "countryCode": "TW",
        "currency": "TWD",
        "Nationality" : "Taiwanese",
        "CurrencySymbol" : "NT$",
        "baseUrl": "https://taiwan-live-api.talentasker.com",
        "imageURL" : "../../../assets/Taiwan.png",
    }
}